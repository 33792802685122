import countryDeOther from '../lists/country_de_other'
import bankAccountType from '../lists/bank_account_type'
import loanTerm from '../lists/loan_term'
import loanPurpose from '../lists/loan_purpose'
import loanPayout from '../lists/loan_payout'
import payoutAccountHolder from '../lists/payout_account_holder'
import rateDueOn from '../lists/rate_due_on'
import rdiType from '../lists/rdi_type'
import vehiclePowerUnit from '../lists/vehicle_power_unit'
import vehicleType from '../lists/vehicle_type'
import fintec from '../lists/fintec'
import yesNoEnum from '../lists/yes_no_enum'

const common = {
  lists: {
    brandTracking: {
      label: 'Wie sind Sie auf smava aufmerksam geworden?'
    },
    sortQuestions: {
      label:
        'Welches dieser Merkmale ist für Ihre Kreditanfrage am wichtigsten?'
    }
  },
  amount: {
    label: 'Kreditbetrag',
    tooltip: '',
    placeholder: 'Kreditbetrag',
    errorMap: {
      missing: 'Bitte geben Sie die Höhe des gewünschten Kreditbetrags an.',
      invalid: 'Bitte korrigieren Sie die Höhe des gewünschten Kreditbetrags.',
      wrong_format:
        'Bitte geben Sie eine gültigen Wert für den Kreditbetrag an.'
    }
  },
  term: {
    label: 'Kreditlaufzeit',
    tooltip: '',
    placeholder: 'Kreditlaufzeit auswählen',
    options: loanTerm,
    errorMap: {
      missing: 'Bitte wählen Sie eine Laufzeit aus.'
    }
  },
  purpose: {
    label: 'Verwendungszweck',
    tooltip: '',
    placeholder: 'Verwendungszweck auswählen',
    options: loanPurpose,
    errorMap: {
      missing: 'Bitte geben Sie einen Verwendungszweck an.'
    }
  },
  manuallyAdaptedAmount: {
    label: 'Anderer Kreditbetrag',
    tooltip:
      'Bitte geben Sie den angepassten Wunschbetrag ein, den Sie beantragen möchten. Der Betrag muss größer oder gleich der Restschuld aller umzuschuldenden Kredite sein.',
    placeholder: 'z.B. 15000',
    errorMap: {
      missing: 'Bitte geben Sie den abweichenden Kreditbetrag an.',
      invalid: 'Bitte korrigieren Sie den Betrag.',
      wrong_format: 'Bitte geben Sie einen gültigen Betrag an.'
    }
  },
  dataProcessingConsent: {
    label: 'Einwilligungserklärung',
    tooltip: '',
    placeholder: ''
  },
  hasAcceptedTerms: {
    label: 'Bitte akzeptieren Sie die AGB',
    tooltip: '',
    placeholder: ''
  },
  loanPayout: {
    label: 'Gewünschter Auszahlungstermin',
    tooltip: '',
    placeholder: '',
    options: loanPayout,
    errorMap: {
      missing:
        'Bitte geben Sie an, zu wann Sie die Auszahlung der Kreditsumme wünschen.'
    }
  },
  loanPayoutDate: {
    label: 'Auszahlungstermin',
    tooltip:
      'Der gewünschte Auszahlungstermin muss mindestens 2 Tage und darf höchstens 180 Tage in der Zukunft liegen.',
    placeholder: 'TT.MM.JJJJ',
    errorMap: {
      missing: 'Bitte geben Sie den gewünschten Auszahlungstermin an.',
      invalid: 'Bitte korrigieren Sie den Auszahlungstermin.',
      wrong_format: 'Bitte geben Sie ein gültiges Datum an (TT.MM.JJJJ).',
      out_of_range:
        'Der Auszahlungstermin muss mindestens 2 Tage in der Zukunft liegen und innerhalb der nächsten 6 Monate stattfinden.'
    }
  },
  payoutAccount: {
    accountNumber: {
      label: 'Kontonummer',
      tooltip: '',
      placeholder: 'z.B. 0648489890',
      errorMap: {
        missing: 'Bitte geben Sie Ihre Kontonummer an.',
        invalid: 'Bitte korrigieren Sie Ihre Kontonummer.',
        wrong_format: 'Bitte geben Sie eine gültige Kontonummer an.'
      }
    },
    bankCode: {
      label: 'Bankleitzahl',
      tooltip: '',
      placeholder: 'z.B. 50010517',
      errorMap: {
        missing: 'Bitte geben Sie Ihre Bankleitzahl an.',
        invalid: 'Bitte korrigieren Sie die Bankleitzahl.',
        wrong_format: 'Bitte geben Sie eine gültige Bankleitzahl an.'
      }
    },
    bic: { label: 'bic', tooltip: '', placeholder: '' },
    country: {
      label: 'Land',
      tooltip: '',
      placeholder: 'Land auswählen',
      options: countryDeOther,
      errorMap: {
        missing: 'Bitte wählen Sie ein Land aus.'
      }
    },
    iban: {
      label: 'IBAN',
      tooltip: '',
      placeholder: 'z.B. DE44 5001 0517 8247 8177 38',
      errorMap: {
        missing: 'Bitte geben Sie Ihre IBAN an.',
        invalid: 'Bitte korrigieren Sie Ihre IBAN.',
        wrong_format: 'Bitte geben Sie eine gültige IBAN an.',
        wrong_length: 'Die Länge der IBAN ist ungültig.'
      }
    },
    type: {
      label: 'Bankverbindung zur Kreditauszahlung',
      tooltip: '',
      placeholder: '',
      options: bankAccountType,
      errorMap: {
        missing: 'Bitte geben Sie die Art der Bankverbindung an.'
      }
    }
  },
  payoutAccountHolder: {
    label: 'Kontoinhaber',
    tooltip:
      'Bitte wählen Sie aus, wer der Kontoinhaber des Kontos ist, auf welches die Kreditsumme eingezahlt und von dem die monatlichen Kreditraten abgebucht werden sollen.',
    placeholder: 'Kontoinhaber auswählen',
    options: payoutAccountHolder,
    errorMap: {
      missing: 'Bitte geben Sie den Kontoinhaber an.',
      invalid: 'Bitte geben Sie einen gültigen Kontoinhaber an.'
    }
  },
  productId: { label: 'productId', tooltip: '', placeholder: '' },
  rateDueOn: {
    label: 'Einzug der Rate zum',
    tooltip: '',
    placeholder: 'Einzug der Rate zum',
    options: rateDueOn,
    errorMap: {
      missing:
        'Bitte geben Sie an, wann die Rate jeweils abgebucht werden soll.'
    }
  },
  rdi: {
    hasAcceptedConsultation: {
      label:
        'Ich habe die aufgeführten Empfehlungen und Hinweise gelesen und verstanden.',
      tooltip: '',
      placeholder: '',
      enums: yesNoEnum,
      errorMap: {
        missing:
          'Bitte geben Sie an, dass Sie die Beratungsinformationen gelesen haben.',
        invalid:
          'Bitte geben Sie an, dass Sie die Beratungsinformationen gelesen haben.'
      }
    },
    isSecondaryDebtorIncluded: {
      label: 'Ratenschutzversicherung für 2. Kreditnehmer mit einschließen?',
      tooltip: '',
      placeholder:
        'Ratenschutzversicherung für 2.&nbsp;Kreditnehmer gewünscht?',
      enums: yesNoEnum
    },
    type: {
      label: 'Wünschen Sie eine Ratenschutzversicherung?',
      tooltip: '',
      placeholder: 'Ratenschutzversicherung',
      options: rdiType
    }
  },
  vehicle: {
    kilometerReading: {
      label: 'Kilometerstand',
      tooltip:
        'Geben Sie hier den Kilometerstand für das zu finanzierende Fahrzeug an.',
      placeholder: 'z.B. 80.000',
      errorMap: {
        missing: 'Bitte geben Sie den Kilometerstand des Fahrzeugs an.',
        invalid: 'Bitte korrigieren Sie den Kilometerstand.',
        wrong_format: 'Bitte geben Sie einen gültigen Kilometerstand an.'
      }
    },
    manufacturer: {
      label: 'Hersteller',
      tooltip:
        'Bitte wählen Sie den Hersteller des Fahrzeugs aus, das Sie finanzieren möchten.',
      placeholder: 'Hersteller auswählen',
      errorMap: {
        missing: 'Bitte teilen Sie uns den Hersteller des Fahrzeugs mit.'
      }
    },
    manufacturingYear: {
      label: 'Baujahr',
      tooltip:
        'Bitte wählen Sie das Baujahr des Fahrzeugs aus, das Sie finanzieren möchten.',
      placeholder: 'Baujahr auswählen',
      errorMap: {
        missing: 'Bitte teilen Sie uns das Baujahr des Fahrzeugs mit.'
      }
    },
    model: {
      label: 'Modell',
      tooltip: '',
      placeholder: 'z.B. Corsa, A4, Golf',
      errorMap: {
        missing: 'Bitte teilen Sie uns das Modell des Fahrzeugs mit.',
        invalid: 'Bitte geben Sie ein gültiges Fahrzeugmodell an.'
      }
    },
    power: {
      label: 'Leistung',
      tooltip:
        'Bitte wählen Sie die Leistung des Fahrzeugs aus, das Sie finanzieren möchten.\n\nHinweis: \nDa Sie die Leistung in kW oder in PS angeben können, achten Sie bitte darauf, welche Einheit Sie gewählt haben.',
      placeholder: 'z.B. 153',
      errorMap: {
        missing: 'Bitte teilen Sie uns die Leistung des Fahrzeugs mit.',
        invalid: 'Bitte korrigieren Sie den Wert.',
        wrong_format: 'Bitte geben Sie einen gültigen Wert an.'
      }
    },
    powerUnit: {
      label: 'Leistung PS oder KW?',
      tooltip: '',
      placeholder: 'Leistungseinheit auswählen',
      options: vehiclePowerUnit,
      errorMap: {
        missing: 'Bitte wählen Sie eine Leistungseinheit.'
      }
    },
    price: {
      label: 'Kaufpreis',
      tooltip: 'Bitte geben Sie hier den Kaufpreis des Fahrzeugs an.',
      placeholder: 'z.B. 6.000',
      errorMap: {
        missing: 'Bitte geben Sie den Kaufpreis des Fahrzeugs an.',
        invalid: 'Bitte korrigieren Sie den Wert.',
        wrong_format: 'Bitte geben Sie einen gültigen Kaufpreis an.'
      }
    },
    type: {
      label: 'Fahrzeugart',
      tooltip:
        'Bitte wählen Sie die Art des Fahrzeuges aus, das sie finanzieren möchten.',
      placeholder: 'Fahrzeugart auswählen',
      options: vehicleType,
      errorMap: {
        missing: 'Bitte wählen Sie eine Fahrzeugart aus.'
      }
    },
    initialPayment: {}
  },
  comment: {
    label: 'Bemerkung zum Kreditantrag',
    tooltip: '',
    placeholder: 'z.B. Informationen zum Verwendungszweck des Kredits'
  },
  salaryAccount: { iban: { label: 'iban', tooltip: '', placeholder: '' } },
  fintec: {
    label: '',
    tooltip: '',
    placeholder: '',
    options: fintec
  }
}

export default common
