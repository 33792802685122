import { NavigateFunction } from 'react-router'
import FFGFormNavigation from './FFGFormNavigation'
import SmavaFormNavigation from 'components/Smava/FormNavigation'
import FormNavigationEditPage from 'components/AbTestComponents/CAT3323/FormNavigtionEditPage'
import { CONFIG_DDF_SMAVA_RCE_EDIT } from 'config/utils/config'
import { useStores } from 'stores/utils/hooks/useStores'

interface Props {
  isSmava?: boolean
  onBack?: () => void
  onNext: () => void
  navigate: NavigateFunction
  isOfferLabel?: boolean
}

const FormNavigationSwitcher = (props: Props) => {
  const sharedProps = {
    onNext: props.onNext,
    onBack: props.onBack,
    navigate: props.navigate
  }

  const {
    page: { config },
    navigation: { currentPageInSession }
  } = useStores()

  const isRCEEditPage =
    config.name === CONFIG_DDF_SMAVA_RCE_EDIT &&
    currentPageInSession === 'editPage'

  if (isRCEEditPage) {
    return <FormNavigationEditPage {...sharedProps} />
  }

  if (props.isSmava) {
    return (
      <SmavaFormNavigation
        {...sharedProps}
        legalContact="info@smava.de"
        isOfferLabel={props.isOfferLabel}
      />
    )
  }

  return (
    <FFGFormNavigation {...sharedProps} legalContact="kredite@finanzcheck.de" />
  )
}
export { FormNavigationSwitcher }
