import {
  CONFIG_DDF,
  CONFIG_DDF_MARKETING,
  CONFIG_DDF_SEM_HARD_DAC,
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_DDF_SMAVA,
  CONFIG_DDF_SMAVA_HARD_DAC_V2,
  CONFIG_DDF_SMAVA_RCE_EDIT,
  CONFIG_DDF_SMAVA_SEPARATED_DAC,
  ConfigName,
  isConfigWithoutInactivityRedirection
} from 'config/utils/config'
import { MIDDLE_AGE, MIN_AGE, YOUNG_AGE } from 'constants/components'

export const SHORT_TIMEOUT = 120000 // 2 minutes
const MEDIUM_TIMEOUT = 180000 // 3 minutes
export const LONG_TIMEOUT = 300000
/**
 * Determines the timeout.
 *
 * @param {number} debtorsAge
 * @param {ConfigName} [configName]
 * @returns {number | null} The timeout duration in milliseconds or null if no timeout is applicable.
 */
export const determineTimeout = (
  debtorsAge: number,
  configName?: ConfigName
): number | null => {
  if (isConfigWithoutInactivityRedirection(configName)) {
    return null
  }

  if (
    configName === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
    configName === CONFIG_DDF_SMAVA_RCE_EDIT ||
    configName === CONFIG_DDF_SMAVA_HARD_DAC_V2
  ) {
    if (debtorsAge >= MIN_AGE && debtorsAge <= YOUNG_AGE) {
      return MEDIUM_TIMEOUT
    }

    if (debtorsAge > YOUNG_AGE && debtorsAge < MIDDLE_AGE) {
      return SHORT_TIMEOUT
    }
  }

  if (configName === CONFIG_DDF_SEPARATED_DAC) {
    if (debtorsAge >= MIN_AGE && debtorsAge <= YOUNG_AGE) {
      return MEDIUM_TIMEOUT
    }

    if (debtorsAge > YOUNG_AGE && debtorsAge < MIDDLE_AGE) {
      return SHORT_TIMEOUT
    }
  }

  if (configName === CONFIG_DDF_SEM_HARD_DAC) {
    if (debtorsAge >= MIN_AGE && debtorsAge <= YOUNG_AGE) {
      return MEDIUM_TIMEOUT
    }

    if (debtorsAge > YOUNG_AGE && debtorsAge < MIDDLE_AGE) {
      return SHORT_TIMEOUT
    }
  }

  if (configName === CONFIG_DDF_SMAVA) {
    if (debtorsAge >= MIN_AGE && debtorsAge <= YOUNG_AGE) {
      return MEDIUM_TIMEOUT
    }
    if (debtorsAge < MIDDLE_AGE) {
      return SHORT_TIMEOUT
    }
    return LONG_TIMEOUT
  }

  if (
    (configName === CONFIG_DDF || configName === CONFIG_DDF_MARKETING) &&
    debtorsAge <= YOUNG_AGE
  ) {
    return MEDIUM_TIMEOUT
  }

  return null
}
