import { useEffect, memo, useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useInView } from 'react-intersection-observer'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import { LoadingMode } from 'components/LoadingProvider'
import HotlineFallback from 'components/HotlineFallback'
import { useErrorState } from 'context/Error/hook'
import CategoryLayout from 'components/Layout/Category'
import PreviousPageErrors from 'components/PageErrors/PreviousPageErrors'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useStores } from 'stores/utils/hooks/useStores'
import { useLoadingMode } from 'stores/utils/hooks/useLoadingMode'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { useDebouncedEffect } from 'hooks/useDebouncedEffect'
import { Button, Theme } from '@mui/material'
import { useAsyncEffect } from 'hooks/useAsyncEffect'
import { NavigateFunction } from 'react-router-dom'
import useEmotionStyles from 'hooks/useEmotionStyles'
import { useAgeGroup } from 'stores/utils/hooks/useAgeGroup'
import { AgeGroup } from 'constants/components'
import { CONFIG_DDF_SMAVA, configMap } from 'config/utils/config'

type Props = {
  onNext: () => void
  navigate: NavigateFunction
  showPreviousPageError?: boolean
}

const styles = (theme: Theme) => ({
  flexBasisGrid: {
    flexBasis: 'auto'
  },
  button: {
    maxHeight: '48px',
    '@media (max-width: 768px)': {
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`
      }
    }
  },
  buttonFullHeight: {
    height: '100%',
    '& button': {
      height: '100%'
    }
  }
})

const FormNavigationEditPage = observer(
  ({ navigate, showPreviousPageError = true, onNext }: Props) => {
    const {
      rootStore: {
        isInIframe,
        setRceEditPageSeen,
        page: { setConfiguration }
      },
      rootStore
    } = useRootStore()

    const {
      state: { message },
      hasCooldown
    } = useErrorState()

    const { ref, inView } = useInView()

    const onDataChanged = async () => {
      setRceEditPageSeen(true)
      await setConfiguration((await configMap[CONFIG_DDF_SMAVA]()).default)
      rootStore.history.replace('base')
      navigate('base')
    }

    useDebouncedEffect(
      () => {
        if (inView) {
          trackInAmplitude(AmplitudeEvent.CtaIsVisible)
        }
      },
      300,
      [inView]
    )

    const {
      page: { getCurrentIndex, scrollToFirstError },
      navigation: { currentPageInSession, handleFinalizeErrors },
      fieldErrors,
      traversal: { patch, finalize, acceptTermsAndConditions }
    } = useStores()

    const loadingMode = useLoadingMode()
    const ageGroup = useAgeGroup()
    const currentIndex = getCurrentIndex()

    const scrollToError = useCallback(
      () => scrollToFirstError(isInIframe),
      [isInIframe, scrollToFirstError]
    )

    const [hasErrorsOnCurrentPage, setHasErrorsOnCurrentPage] = useState(false)
    const [previousErrorPageName, setPreviousErrorPageName] = useState<string>()

    const handleSubmitButtonClick = async () => {
      await patch([
        { op: 'set', path: '/common/hasAcceptedTerms', value: true }
      ])
      acceptTermsAndConditions()
      if (ageGroup !== AgeGroup.OLD_AGE_GROUP) {
        onNext()
        return
      }

      finalize(handleFinalizeErrors)
    }

    useAsyncEffect(async () => {
      if (!currentPageInSession) {
        return
      }

      const errorsOnCurrentPage =
        await fieldErrors.getErrors(currentPageInSession)
      const hasErrorsOnCurrentPageFlag =
        Object.keys(errorsOnCurrentPage).length > 0

      setHasErrorsOnCurrentPage(hasErrorsOnCurrentPageFlag)

      const previousErrorPageNameFlag =
        await fieldErrors.findFirstErrorPage(currentIndex)

      setPreviousErrorPageName(previousErrorPageNameFlag)
    }, [currentPageInSession, loadingMode])

    useEffect(() => {
      if (loadingMode === LoadingMode.None && hasErrorsOnCurrentPage) {
        scrollToError()
      }
    }, [hasErrorsOnCurrentPage, loadingMode, scrollToError])

    const { flexBasisGrid, button, buttonFullHeight } = useEmotionStyles(
      styles,
      'primary'
    )
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if (
      previousErrorPageName &&
      !hasErrorsOnCurrentPage &&
      showPreviousPageError
    ) {
      setRceEditPageSeen(true)
      return (
        <PreviousPageErrors
          navigate={navigate}
          pageName={previousErrorPageName}
        />
      )
    }

    return (
      <CategoryLayout ref={ref}>
        {message && <HotlineFallback message={message} />}
        <Grid
          container
          spacing={1}
          direction={isMobile ? 'column' : 'row-reverse'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            className={flexBasisGrid}
            container
            item
            spacing={1}
            direction={isMobile ? 'column' : 'row-reverse'}
            xs={12}
          >
            <Grid item xs={12}>
              <Button
                className={button}
                size="large"
                fullWidth
                id="next-submit"
                name="next-submit"
                variant="contained"
                color={'primary'}
                endIcon={
                  loadingMode === LoadingMode.None ? (
                    <ArrowForwardIcon />
                  ) : undefined
                }
                disabled={hasCooldown}
                onClick={handleSubmitButtonClick}
              >
                Weiter zu den Angeboten
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              data-testid="grid-item-next-submit-container"
              display="flex"
              alignItems="center"
            >
              <Button
                className={buttonFullHeight}
                size="large"
                fullWidth
                variant={'outlined'}
                color="primary"
                disabled={hasCooldown}
                onClick={onDataChanged}
              >
                Weitere Angaben ändern
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CategoryLayout>
    )
  }
)

export default memo(FormNavigationEditPage)
