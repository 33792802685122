import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { logAndTrackError } from 'utils/log/logAndTrackError'
import { EntryPoint } from 'startup/command/loadEntryPoint'
import { logWarning } from 'utils/log'
import { getEnv } from 'utils/env'
import { getTrackingPath, isTenantSmava } from 'utils/url'
import { BestOffer } from 'components/BestOfferBox/BestOffer.interface'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { Data } from 'interfaces/ApolloResponse.interface'

import {
  AFFILIATE_ID_FFG,
  AFFILIATE_ID_SMAVA,
  AffiliateEventName,
  sendAffiliateEvent
} from '../affiliate'
import {
  TrackEventData,
  TrackBestOfferData,
  TransactionTeal
} from './tracking.interface'
import RootStore from '../../stores/RootStore'
import { AmplitudeEvent } from './amplitude/amplitude.interface'
import { trackInAmplitude } from './amplitude'
import { PossibleVariants } from './optimizely/types'
import { Experiments } from 'CustomOptimizelyProvider'

const isSmavaTrackingEvent = isTenantSmava()

declare global {
  interface Window {
    // This function is only for the pre-rendering build step
    analytics: { page: (args: unknown) => void }
  }
}
interface tealProps {
  transaction: TransactionTeal
  affiliateId?: string | number | undefined
  subId?: string | number | undefined
  refId?: string | number | undefined
  configurationId?: string | number | undefined
  traversalPersonId?: string | number | undefined
  category?: string | undefined
  step?: string | undefined
  context?: Record<string, unknown>
  advertisementId?: string
}

export function trackTeal({
  transaction,
  affiliateId,
  subId,
  refId,
  configurationId,
  traversalPersonId,
  step,
  context
}: tealProps) {
  broadcastToParent({
    eventName: RegisteredEventName.tracking,
    data: {
      ...transaction,
      segmentWriteKey: getEnv('REACT_APP_SEGMENT_WRITE_KEY'),
      ...(affiliateId && { affiliateId }),
      ...(subId && { subId }),
      ...(refId && { refId }),
      ...(configurationId && { configId: configurationId }),
      ...(traversalPersonId && { personId: traversalPersonId }),
      step: step || getTrackingPath(),
      ...((getCookie(COOKIE_KEY_FCID) as string) && {
        fcId: getCookie(COOKIE_KEY_FCID) as string
      }),
      ...(context && {
        context
      })
    }
  })
}

/*remove after FTM is removed*/
export function pushGTMEvent<T extends TrackEventData>(event: T) {
  if (!isSmavaTrackingEvent) {
    broadcastToParent({ eventName: RegisteredEventName.tracking, data: event })
  }
}

export const trackPageView = ({
  navigation: { currentPageInSession },
  traversal: {
    data: {
      system: {
        advertisementId,
        affiliateId = isSmavaTrackingEvent
          ? AFFILIATE_ID_SMAVA
          : AFFILIATE_ID_FFG,
        subId,
        refId,
        configurationId
      }
    }
  }
}: RootStore) => {
  const eventData: TrackEventData = {
    event: 'iframePageview',
    step: currentPageInSession || '',
    conf: advertisementId,
    affiliate: affiliateId.toString()
  }
  broadcastToParent({
    eventName: RegisteredEventName.trackPageVisit,
    data: {
      step: getTrackingPath(),
      affiliateId,
      subId,
      refId,
      configId: configurationId,
      segmentWriteKey: getEnv('REACT_APP_SEGMENT_WRITE_KEY')
    }
  })
  pushGTMEvent(eventData)
}

// The leads should already exist for these events, see CAT-3592
export const ignoredEntryPoints = [
  EntryPoint.LEAD_ABANDONED,
  EntryPoint.LEAD_ABORTED,
  EntryPoint.LEAD_SAVED
]

export const trackPayoutAccountPageView = async (rootStore: RootStore) => {
  const { initialEntryPoint, traversal } = rootStore

  const {
    traversalId,
    data: {
      common: { amount, purpose, term },
      system: {
        affiliateId = isSmavaTrackingEvent
          ? AFFILIATE_ID_SMAVA
          : AFFILIATE_ID_FFG,
        subId,
        refId,
        configurationId,
        advertisementId
      },
      debtors: { count }
    }
  } = traversal

  const transaction: TransactionTeal = {
    event: 'payout_account_page_view',
    label: getTrackingPath(),
    traversalId: traversalId || undefined,
    amount,
    purpose,
    term,
    category: 'loan_status',
    products: [
      {
        product_id: purpose,
        quantity: 1,
        price: amount
      }
    ]
  }

  try {
    trackTeal({
      transaction,
      affiliateId,
      subId,
      refId,
      configurationId,
      context: {
        Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
        userAttributes: {
          advertisementId,
          debtorCount: count
        }
      }
    })
  } catch (error) {
    logAndTrackError(new Error('Error when payout_account_page_view'))
  }

  if (initialEntryPoint && ignoredEntryPoints.includes(initialEntryPoint)) {
    logWarning(
      `Lead was not tracked because the entry point is "${initialEntryPoint}".`
    )
    return
  }
}

export const trackLead = async (rootStore: RootStore) => {
  const { initialEntryPoint, traversal } = rootStore

  const {
    traversalId,
    data: {
      common: { amount, purpose, term },
      system: {
        affiliateId = isSmavaTrackingEvent
          ? AFFILIATE_ID_SMAVA
          : AFFILIATE_ID_FFG,
        subId,
        refId,
        configurationId
      }
    }
  } = traversal

  const transaction: TransactionTeal = {
    event: 'all_lead',
    label: getTrackingPath(),
    traversalId: traversalId || undefined,
    amount,
    purpose,
    term,
    category: 'loan_status',
    products: [
      {
        product_id: purpose,
        quantity: 1,
        price: amount
      }
    ]
  }

  try {
    trackTeal({
      transaction,
      affiliateId,
      subId,
      refId,
      configurationId
    })

    const emailPrimaryDebtor = document.getElementsByName(
      'debtors.primary.contact.email'
    )[0] as HTMLButtonElement

    if (emailPrimaryDebtor && !emailPrimaryDebtor.disabled) {
      sendAffiliateEvent(AffiliateEventName.Conversion, traversal, rootStore)
    }
  } catch (error) {
    // CAT-3850 We throw this to be able to filter in Bugsnag
    logAndTrackError(new Error('Error when trackAllLead'))
  }

  if (initialEntryPoint && ignoredEntryPoints.includes(initialEntryPoint)) {
    logWarning(
      `Lead was not tracked because the entry point is "${initialEntryPoint}".`
    )
    return
  }
}

export const trackFinal = async ({ traversal, experimentData }: RootStore) => {
  let eventData: TrackEventData = {
    event: 'iframeFinal'
  }
  if (traversal?.data && traversal?.traversalId) {
    const {
      common: { productId, purpose, amount, term, amountAdaption },
      system: {
        affiliateId = isSmavaTrackingEvent
          ? AFFILIATE_ID_SMAVA
          : AFFILIATE_ID_FFG,
        subId,
        refId,
        configurationId,
        advertisementId
      },
      debtors: {
        primary: { personId: traversalPersonId, activeLoans },
        count
      }
    } = traversal.data

    eventData = {
      ...eventData,
      traversalId: traversal.traversalId,
      name: productId,
      purpose,
      revenue: amount,
      affiliate: affiliateId.toString()
    }

    const tealEventData = {
      event: 'final',
      label: '/kreditantrag/payoutAccount',
      traversalId: traversal?.traversalId || '',
      amount,
      purpose,
      term,
      category: 'loan_status',
      debtorCount: count
    }

    trackTeal({
      transaction: tealEventData,
      affiliateId,
      subId,
      refId,
      configurationId,
      traversalPersonId,
      context: {
        Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
        userAttributes: {
          advertisementId,
          debtorCount: count,
          amountAdaption,
          isDebtConversionWantedInAtLeastOneLoan: activeLoans?.some(
            (activeLoan) => activeLoan.isDebtConversionWanted
          )
        }
      }
    })
  }

  pushGTMEvent(eventData)
}

export const trackBestOfferView = async (
  _: RootStore,
  amount: number,
  term: number,
  purpose: string,
  bestOffer: BestOffer
) => {
  const event: TrackBestOfferData = {
    event: 'best-offer',
    offer: {
      amount,
      purpose,
      term,
      effectiveInterestRate: bestOffer.effectiveInterestRate,
      legalText: bestOffer.legalText,
      monthlyRate: bestOffer.monthlyRate
    }
  }

  pushGTMEvent(event)
  trackInAmplitude(AmplitudeEvent.DisplayBestOfferBox, {
    productId: bestOffer.product.id
  })
}

export const getOptimizelyTrackingAttribute = (data: Data) => {
  const userAttributesObject = {
    advertisementId: data.system.advertisementId || null
  }
  return userAttributesObject
}
