import { useRef, useEffect, useCallback, memo, useState } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'stores/utils/hooks/useStores'
import { useField } from 'react-final-form'
import { trackInAmplitude } from 'utils/tracking/amplitude'
import { AmplitudeEvent } from 'utils/tracking/amplitude/amplitude.interface'
import { useInteraction } from 'hooks/useInteraction'
import { usePayoutAccountValidation } from 'hooks/usePayoutAccountValidation'
import {
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_DDF_SMAVA_RCE_EDIT,
  CONFIG_DDF_SMAVA_SEPARATED_DAC
} from 'config/utils/config'

export enum InactivityType {
  PAYOUT_ACCOUNT_INACTIVITY,
  DAC_INACTIVITY
}

interface IdleDetectorProps {
  onNext: () => void
  timeout: number
  inactivityType?: InactivityType
}

const IBAN_PATH = 'common.payoutAccount.iban'
const ACCOUNT_NUMBER_PATH = 'common.payoutAccount.accountNumber'
const BANKCODE_PATH = 'common.payoutAccount.bankCode'

const CreateFinalAfterInactivity = observer(
  ({ onNext, timeout, inactivityType }: IdleDetectorProps) => {
    const {
      page: { isLastDisplayedPage, config },
      navigation: { currentPageInSession },
      fieldErrors: { isErrorOnFinalize },
      traversal: { dacWidgetState }
    } = useStores()
    const { hasInteracted, setHasInteracted } = useInteraction()
    const isLastPage = isLastDisplayedPage(currentPageInSession)

    const ibanField = useField(IBAN_PATH, {})
    const accountNumberField = useField(ACCOUNT_NUMBER_PATH, {})
    const bankCodeField = useField(BANKCODE_PATH, {})

    const timeoutRef = useRef<number | undefined>(undefined)
    const [isTimeoutStarted, setIsTimeoutStarted] = useState(false)

    const isTouchedIgnored =
      config.name === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
      config.name === CONFIG_DDF_SEPARATED_DAC ||
      config.name === CONFIG_DDF_SMAVA_RCE_EDIT

    const createFinalAfterInactivityAfterEnteringPayountAccount =
      useCallback(() => {
        trackInAmplitude(
          AmplitudeEvent.FinalAfterInactivityAfterEnteringPayoutAccount
        )
        onNext?.()
      }, [onNext])

    const createFinalAfterInactivityAfterDACInitialization = useCallback(() => {
      trackInAmplitude(
        AmplitudeEvent.FinalAfterInactivityAfterDACInitialization
      )
      onNext?.()
    }, [onNext])

    const isPayoutAccountReady = usePayoutAccountValidation(isTouchedIgnored)

    const createFinalAfterInactivity = useCallback(() => {
      if (isErrorOnFinalize) {
        return
      }

      const isPayoutAccountReadyAndIsDacInitialOrDisagreed =
        isPayoutAccountReady &&
        isLastPage &&
        (dacWidgetState === 'initial' || dacWidgetState === 'disagreed')

      const isPayoutAccountReadyAndIsDacAgreed =
        isPayoutAccountReady &&
        isLastPage &&
        (dacWidgetState === 'agreed' || dacWidgetState === 'error')

      if (
        inactivityType === InactivityType.PAYOUT_ACCOUNT_INACTIVITY &&
        isPayoutAccountReadyAndIsDacInitialOrDisagreed
      ) {
        trackInAmplitude(
          AmplitudeEvent.FinalAfterInactivityAfterEnteringPayoutAccount
        )
        createFinalAfterInactivityAfterEnteringPayountAccount()
      }

      if (
        inactivityType === InactivityType.DAC_INACTIVITY &&
        isPayoutAccountReadyAndIsDacAgreed
      ) {
        trackInAmplitude(
          AmplitudeEvent.FinalAfterInactivityAfterDACInitialization
        )
        createFinalAfterInactivityAfterDACInitialization()
      }
    }, [
      inactivityType,
      isErrorOnFinalize,
      dacWidgetState,
      isPayoutAccountReady,
      createFinalAfterInactivityAfterEnteringPayountAccount,
      createFinalAfterInactivityAfterDACInitialization
    ])

    const stopTimeout = useCallback(() => {
      clearTimeout(timeoutRef.current)
      setIsTimeoutStarted(false)
    }, [setIsTimeoutStarted])

    const startTimeout = useCallback(() => {
      timeoutRef.current = window.setTimeout(() => {
        createFinalAfterInactivity()
        setIsTimeoutStarted(false)
      }, timeout)
      setIsTimeoutStarted(true)
    }, [createFinalAfterInactivity, setIsTimeoutStarted, timeout])

    const resetTimeout = useCallback(() => {
      stopTimeout()
      startTimeout()
    }, [stopTimeout, startTimeout])

    const isPayoutAccountFieldActive =
      ibanField.meta.active ||
      accountNumberField.meta.active ||
      bankCodeField.meta.active

    // active event is not caught by event listener, it needs to be done manually
    useEffect(() => {
      if (isTimeoutStarted && isPayoutAccountFieldActive) {
        stopTimeout()
      }

      if (!isTimeoutStarted && !isPayoutAccountFieldActive) {
        startTimeout()
      }
    }, [
      isPayoutAccountFieldActive,
      isTimeoutStarted,
      startTimeout,
      stopTimeout,
      dacWidgetState
    ])

    useEffect(() => {
      if (hasInteracted) {
        resetTimeout()
        setHasInteracted(false)
      }
    }, [hasInteracted, setHasInteracted, resetTimeout])

    useEffect(() => {
      resetTimeout()
    }, [dacWidgetState])

    useEffect(() => {
      return () => stopTimeout()
    }, [stopTimeout])

    return null
  }
)

export default memo(CreateFinalAfterInactivity)
