import { Box, Link, Typography } from '@mui/material'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useText } from 'stores/utils/hooks/useText'

const LegalInfo = () => {
  const {
    text: { copy }
  } = useText()

  const {
    rootStore: { clientDetailsStore }
  } = useRootStore()

  return (
    <Box px={0} mb={2} mt={2}>
      <Typography variant="caption">
        {copy.dac}{' '}
        <Link
          href={clientDetailsStore.clientDetails?.urls?.dataProtection}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Weitere Informationen
        </Link>
        .
      </Typography>
    </Box>
  )
}

export default LegalInfo
