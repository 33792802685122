import { Theme, Grid, Typography } from '@mui/material'
import { memo } from 'react'
import { observer } from 'mobx-react'
import Tuev from 'components/DigitalAccountCheck/TrustElement/Tuev'
import Ekomi from 'components/DigitalAccountCheck/TrustElement/Ekomi'
import GuenstigerGehtNicht from 'components/DigitalAccountCheck/TrustElement/GuenstigerGehtNicht'
import useEmotionStyles from 'hooks/useEmotionStyles'
import LegalInfo from 'components/DigitalAccountCheck/LegalInfo'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { PossibleVariants } from 'utils/tracking/optimizely/types'
import { Experiments } from 'CustomOptimizelyProvider'
import { useStores } from 'stores/utils/hooks/useStores'

const styles = (theme: Theme) => ({
  footerContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    margin: '0 auto',
    maxWidth: '532px'
  },
  parentContainer: {
    padding: theme.spacing(2)
  },
  footerTitle: {
    marginBottom: theme.spacing(2)
  },
  hardDacLegalTextContainer: {
    margin: '0 auto',
    maxWidth: '532px',
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}`
    }
  }
})

interface Props {
  isNotAffiliate?: boolean
}

const DDFTrustCards = observer(({ isNotAffiliate }: Props) => {
  const { footerContainer, footerTitle, hardDacLegalTextContainer } =
    useEmotionStyles(styles)
  const { rootStore } = useRootStore()
  const {
    navigation: { currentPageInSession }
  } = useStores()
  const { experimentData } = rootStore
  const variation = experimentData[Experiments.CAT_3316_DAC_HARD_V2]
  const isLastPage = currentPageInSession === 'dacSmavaHard'

  return (
    <>
      {variation !== PossibleVariants.CONTROL && isLastPage && (
        <Grid className={hardDacLegalTextContainer}>
          <LegalInfo />
        </Grid>
      )}
      <Grid className={footerContainer}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" align="center" className={footerTitle}>
            {isNotAffiliate ? 'TÜV geprüft + ' : ''}SCHUFA-neutral
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            {isNotAffiliate && (
              <Grid item>
                <Tuev width="80px" />
              </Grid>
            )}
            <Grid item>
              <GuenstigerGehtNicht />
            </Grid>
            <Grid item>
              <Ekomi />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
})

export default memo(DDFTrustCards)
