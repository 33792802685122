export enum OfferSorting {
  MONTHLY_RATE = 'monthlyRate',
  EFFECTIVE_INTEREST_RATE = 'effectiveInterestRate',
  PRE_SCORE = 'prescore',
  PAYOUT_SPEED = 'payoutSpeed'
}

const offerSortingList = [
  { label: 'Monatliche Rate', value: OfferSorting.MONTHLY_RATE },
  {
    label: 'Zinssatz',
    value: OfferSorting.EFFECTIVE_INTEREST_RATE
  },
  {
    label: 'Empfehlung der besten Angebote',
    value: OfferSorting.PRE_SCORE
  },
  {
    label: 'Auszahlungsgeschwindigkeit',
    value: OfferSorting.PAYOUT_SPEED
  }
]

export default offerSortingList
