/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory, History } from 'history'
import { action, observable, makeObservable } from 'mobx'

import { getFfgUrlParams } from 'utils/url'
import { EntryPoint } from 'startup/command/loadEntryPoint'

import PageStore from './PageStore'
import TraversalStore from './TraversalStore'
import ThemeStore from './ThemeStore'
import { LoadingMode } from '../components/LoadingProvider'
import FieldErrorStore from './FieldErrorStore'
import Navigation from './helpers/Navigation'
import FcidStore from './FcidStore'
import ClientDetailsStore from './ClientDetailsStore'

export function isInIframeContext() {
  if (typeof window === 'undefined' || !window) {
    return false
  }

  return window.top !== window
}

class RootStore {
  public traversal: TraversalStore
  public page: PageStore
  public fieldErrors: FieldErrorStore
  public theme: ThemeStore
  public history: History
  public navigation: Navigation
  public fcid: FcidStore
  public clientDetailsStore: ClientDetailsStore

  public urlParams = getFfgUrlParams(window.location.search)
  public isInIframe = isInIframeContext()
  public isFooterVisible = true
  public loadingMode = LoadingMode.Initial
  public initialEntryPoint: EntryPoint = EntryPoint.FURTHEST
  public experimentData = {}
  public resumeDetailsFetched = false
  public isRceEditPageSeen = false

  constructor() {
    makeObservable(this, {
      urlParams: observable,
      isInIframe: observable,
      isFooterVisible: observable,
      loadingMode: observable,
      setIsInIframe: action,
      setLoadingMode: action,
      setIsFooterVisible: action,
      setInitialEntryPoint: action
    })

    this.history = createBrowserHistory()
    this.page = new PageStore(this)
    this.fieldErrors = new FieldErrorStore(this)
    this.traversal = new TraversalStore(this)
    this.theme = new ThemeStore()
    this.navigation = new Navigation(this)
    this.clientDetailsStore = new ClientDetailsStore()

    this.fcid = new FcidStore(this.urlParams)
  }

  setIsInIframe = (isInIframe: boolean) => {
    this.isInIframe = isInIframe
  }

  setLoadingMode = (loadingMode: LoadingMode) => {
    this.loadingMode = loadingMode
  }

  setIsFooterVisible = (isVisible: boolean) => {
    this.isFooterVisible = isVisible
  }

  setInitialEntryPoint(entryPoint: EntryPoint) {
    this.initialEntryPoint = entryPoint
  }

  setExperimentData = (experimentData: Record<string, string>) => {
    this.experimentData = experimentData
  }

  setResumeDetailsFetched = (resumeDetailsFetched: boolean) => {
    this.resumeDetailsFetched = resumeDetailsFetched
  }

  setRceEditPageSeen = (rceEditPageSeen: boolean) => {
    this.isRceEditPageSeen = rceEditPageSeen
  }
}

export default RootStore
