import { memo } from 'react'

import CreateFinalAfterInactivity, {
  InactivityType
} from './containers/CreateFinalAfterInactivity'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { getAge } from 'utils/date'
import { determineTimeout, LONG_TIMEOUT } from 'InactivityContainer.helpers'
import { Experiments } from 'CustomOptimizelyProvider'
import { PossibleVariants } from 'utils/tracking/optimizely/types'
import { YOUNG_AGE } from 'constants/components'

type InactivityProps = {
  handleSubmit: () => void
}

const InactivityContainer = ({ handleSubmit }: InactivityProps) => {
  const {
    rootStore: {
      page: { config },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      },
      experimentData
    }
  } = useRootStore()

  const debtorsAge = getAge(birthday)
  let timeout = determineTimeout(debtorsAge, config.name)

  if (!timeout) {
    return null
  }

  const variation = experimentData[Experiments.CAT_3316_DAC_HARD_V2]

  /* remove after CAT_3316_DAC_HARD_V2 experiment */
  if (
    config.name === 'ddf_smava_hard_dac_v2' &&
    variation === PossibleVariants.VARIATION_C &&
    debtorsAge > YOUNG_AGE
  ) {
    timeout = 60000
  }

  return (
    <>
      <CreateFinalAfterInactivity
        timeout={LONG_TIMEOUT}
        inactivityType={InactivityType.PAYOUT_ACCOUNT_INACTIVITY}
        onNext={handleSubmit}
      />
      <CreateFinalAfterInactivity
        timeout={timeout}
        inactivityType={InactivityType.DAC_INACTIVITY}
        onNext={handleSubmit}
      />
    </>
  )
}

export default memo(InactivityContainer)
