import { memo } from 'react'
import { Theme, Typography } from '@mui/material'
import useEmotionStyles from 'hooks/useEmotionStyles'
import { useRootStore } from 'stores/utils/hooks/useRootStore'

interface MotivationalTextBelowProps {
  percentage: number
  label?: string
}

const LAST_STEP_TEXT = 'Letzter Schritt!'
const TWO_STEPS_LEFT_TEXT = 'Nur noch 2 Schritte'
const THREE_STEPS_LEFT_TEXT = 'Nur noch 3 Schritte'

const PAGE_WITH_CUSTOM_TEXT = {
  payoutAccount: LAST_STEP_TEXT,
  rdi: TWO_STEPS_LEFT_TEXT,
  activeLoans: THREE_STEPS_LEFT_TEXT,
  vehicle: THREE_STEPS_LEFT_TEXT,
  payoutWithoutDac: TWO_STEPS_LEFT_TEXT,
  dacSmavaHard: LAST_STEP_TEXT
}

const getMessage = (
  percentage: number,
  currentPageInSession: string | null,
  isCarLoanPurpose: boolean,
  label?: string
): string => {
  if (
    currentPageInSession === 'payoutAccount' ||
    currentPageInSession === 'payoutWithoutDac' ||
    currentPageInSession === 'rdi' ||
    currentPageInSession === 'dacSmavaHard' ||
    (currentPageInSession === 'activeLoans' && !isCarLoanPurpose) ||
    (currentPageInSession === 'vehicle' && isCarLoanPurpose)
  ) {
    return PAGE_WITH_CUSTOM_TEXT[currentPageInSession]
  }

  if (percentage) {
    return `${percentage} % ${label || 'geschafft'}`
  }
  return ''
}

const MotivationalTextBelow = ({
  percentage,
  label
}: MotivationalTextBelowProps) => {
  const { motivationalTextBelow } = useEmotionStyles(styles)

  const {
    rootStore: {
      navigation: { currentPageInSession },
      traversal: {
        data: {
          common: { purpose }
        }
      }
    }
  } = useRootStore()

  const isCarLoanPurpose = purpose === 'PRE_OWNED_CAR' || purpose === 'NEW_CAR'

  const message = getMessage(
    percentage,
    currentPageInSession,
    isCarLoanPurpose,
    label
  )

  if (!message) {
    return null
  }

  return (
    <Typography variant="caption" className={motivationalTextBelow}>
      {message}
    </Typography>
  )
}

export default memo(MotivationalTextBelow)

const styles = (theme: Theme) => ({
  motivationalTextBelow: {
    color: theme.palette.grey[800]
  }
})
