/* eslint-disable react/no-unescaped-entities */
import { memo } from 'react'
import { Link, Typography } from '@mui/material'
import CategoryLayout from 'components/Layout/Category'

import { useClientDetails } from 'stores/utils/hooks/useClientDetails'

const Agb = () => {
  const clientDetails = useClientDetails()

  return (
    <CategoryLayout>
      <Typography variant="body2" sx={{ marginTop: '16px' }} color="grey.600">
        {`Mit Klick auf den "Weiter zu den Angeboten"-Button akzeptiere ich die`}{' '}
        <Link
          href={clientDetails?.urls?.termsAndConditions}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          AGB
        </Link>{' '}
        und erteile smava einen kostenlosen Kreditvergleichs- und
        Vermittlungsauftrag. Ich habe die{' '}
        <Link
          href={clientDetails?.urls?.precontractual}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Pflichtinformationen
        </Link>{' '}
        und die{' '}
        <Link
          href={clientDetails?.urls?.dataProtection}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          Datenschutzhinweise
        </Link>{' '}
        erhalten.
      </Typography>
    </CategoryLayout>
  )
}

export default memo(Agb)
