/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { Experiments, optimizelyClient } from 'CustomOptimizelyProvider'
import RootStore from 'stores/RootStore'

interface DecisionEvent {
  campaign_id: string
  experiment_id: string
  variation_id: string
}

interface Input {
  ruleKey: string
  variationKey: string
}

export function getDecisionDetails(
  parsedDatafile: any,
  input: Input
): DecisionEvent {
  const df = parsedDatafile
  const result: DecisionEvent = {
    campaign_id: '',
    experiment_id: '',
    variation_id: ''
  }

  if (!df || !df.experiments) {
    return {
      campaign_id: '',
      experiment_id: '',
      variation_id: ''
    }
  }

  const getExperiment = (e: any) => e.key === input.ruleKey

  const experiment =
    df.experiments.find(getExperiment) ||
    df.groups
      .map((group: any) => group.experiments.find(getExperiment))
      .filter((experimentInGroup: any) => experimentInGroup)[0]

  if (!experiment) {
    return {
      campaign_id: '',
      experiment_id: '',
      variation_id: ''
    }
  }
  result.experiment_id = experiment.id
  result.campaign_id = experiment.layerId

  if (!experiment.variations) {
    return {
      campaign_id: '',
      experiment_id: '',
      variation_id: ''
    }
  }
  const variation = (experiment.variations as Array<any>).find(
    (v) => v.key === input.variationKey
  )
  if (!variation) {
    return {
      campaign_id: '',
      experiment_id: '',
      variation_id: ''
    }
  }
  result.variation_id = variation.id
  return result
}

export default function getExperimentVariation(
  experiment: Experiments,
  rootStore: RootStore,
  overrideAttributes?: Record<string, unknown>,
  overrideUserId?: string
) {
  if (
    !optimizelyClient.user.id ||
    !optimizelyClient.isReady() ||
    !rootStore.resumeDetailsFetched
  ) {
    return {}
  }
  const userId = getCookie(COOKIE_KEY_FCID) || ''
  const {
    traversal: {
      data: {
        system: { advertisementId }
      }
    }
  } = rootStore

  const { experimentData, setExperimentData } = rootStore
  if (!rootStore.experimentData[experiment] || overrideAttributes) {
    const datafile = JSON.parse(
      optimizelyClient.getOptimizelyConfig()?.getDatafile() || '{}'
    )

    const variation = optimizelyClient.getVariation(
      `${experiment}`,
      overrideUserId || userId,
      {
        advertisementId,
        ...overrideAttributes
      }
    )

    const decisionDetails = getDecisionDetails(datafile, {
      ruleKey: `${experiment}`,
      variationKey: variation!
    })

    if (experiment && variation) {
      setExperimentData({
        ...experimentData,
        [experiment]: variation
      })
      const eventProperties: { [k: string]: any } = {
        experiment_name: `${experiment}`,
        variation_name: variation!,
        ...decisionDetails
      }

      broadcastToParent({
        eventName: RegisteredEventName.tracking,
        data: {
          event: 'Experiment Viewed',
          ...eventProperties,
          context: {
            Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
            userAttributes: {
              advertisementId,
              ...(overrideAttributes && { ...overrideAttributes })
            }
          }
        }
      })
    }

    return {
      variation
    }
  }

  return {
    variation: experimentData[experiment]
  }
}
