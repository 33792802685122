enum Scope {
  Business = 'Business',
  GTM = 'GTM',
  Debug = 'Debug'
}

export enum UserProperty {
  TaurineVersion = 'TaurineVersion',
  IsMobile = 'IsMobile',
  Viewport = 'Viewport',
  ViewportHeight = 'ViewportHeight',
  ViewportWidthXHeight = 'ViewportWidthXHeight',
  DebtorCount = 'DebtorCount',
  Origin = 'Origin',
  ConfigurationID = 'ConfigurationID',
  AdvertisementID = 'AdvertisementID',
  TaurineConfiguration = 'TaurineConfiguration',
  EntryPointInitial = 'EntryPointInitial',
  EntryPointPageName = 'EntryPointPageName',
  EntryPointParent = 'EntryPointParent',
  NetworkType = 'NetworkType',
  ActivityID = 'ActivityID',
  ClientID = 'ClientID',
  AffiliateID = 'AffiliateID',
  PlacementID = 'PlacementID',
  OptimizelyEndUserId = 'OptimizelyEndUserId',
  OptimizelyVariation = 'OptimizelyVariation',
  OptimizelyUserAttributes = 'OptimizelyUserAttributes',
  OptimizelyExperimentName = 'OptimizelyExperimentName',
  BackUrl = 'BackUrl',
  Tenant = 'tenant',
  UtmMedium = 'utmMedium',
  UtmParameters = 'utmParameters',
  UtmCampaign = 'utmCampaign',
  UtmSource = 'utmSource',
  FcId = 'fcId'
}

export enum EventProperty {
  CurrentPage = 'currentPage',
  TraversalId = 'traversalId',
  LastInputTouched = 'lastInputTouched',
  NextInputUntouched = 'nextInputUntouched'
}

// please update the Confluence page when adding a new event
// https://confluence.atl.finanzcheck.de/display/CAT/Amplitude+Events+RR
export enum AmplitudeEvent {
  LogExecutionTime = 'LogExecutionTime',
  LogDependencyTimeout = 'LogDependencyTimeout',
  VisitPage = 'VisitPage',
  ViewPage = 'ViewPage',
  GenerateLead = 'GenerateLead',
  GoToFinal = 'GoToFinal',
  InteractWithField = 'InteractWithField',
  ToggleInformation = 'ToggleInformation',
  ValidateTraversal = 'ValidateTraversal',
  ConditionalFields = 'ConditionalFields',
  AddSecondDebtorClicked = 'AddSecondDebtorClicked',
  AddSecondDebtorSeen = 'AddSecondDebtorSeen',
  RefinancingCalculatorOpen = 'RefinancingCalculatorOpen',
  RefinancingCalculatorSavingsChange = 'RefinancingCalculatorSavingsChange',
  UseSaveButton = 'UseSaveButton',
  DisplayBestOfferBox = 'DisplayBestOfferBox',
  UseCompanyAutocomplete = 'UseCompanyAutocomplete',
  ChangeTabVisibility = 'ChangeTabVisibility',
  DetectAbort = 'DetectAbort',
  UseHotlineFallback = 'UseHotlineFallback',
  ShowHotlineFallback = 'ShowHotlineFallback',
  VisitSealUrl = 'VisitSealUrl',
  AlertSamePayoutAccountUsed = 'AlertSamePayoutAccountUsed',
  ShowConstructionFinancingNotice = 'ShowConstructionFinancingNotice',
  CtaIsVisible = 'CtaIsVisible',
  CtaIsClicked = 'CtaIsClicked',
  BackLinkIsClicked = 'BackLinkIsClicked',
  BackButtonIsClicked = 'BackButtonIsClicked',
  ErrorPageShown = 'ErrorPageShown',
  ErrorOccurred = 'ErrorOccured',
  OnDACSuccessAutoRedirect = 'fintec_dacSuccessAutoRedirect',
  OnDACWithoutClick = 'fintec_withoutDacClick',
  OnDacAccordionClick = 'fintec_accordion_click',
  OnDacWrapperView = 'fintec_dacWrapper_viewed',
  OnDacStateChange = 'fintec_onStateChange',
  OnDacSuccess = 'fintec_onSuccess',
  OnDacError = 'fintec_onError',
  OnDacAbort = 'fintec_onAbort',
  OnDacStepViewAccessConfirmation = 'fintec_stepView_accessConfirmation',
  OnDacStepViewRegistrationSuccessful = 'fintec_stepView_registrationSuccessful',
  OnDacTranscationalIdNull = 'OnDacTranscationalIdNull',
  OnLoginClicked = 'OnLoginClicked',
  OnTaurineStartsLoading = 'OnTaurineStartsLoading',
  OnTaurineBecomesUsable = 'OnTaurineBecomesUsable',
  ParentFrameStartLoadTime = 'ParentFrameStartLoadTime',
  FinalAfterInactivityAfterEnteringPayoutAccount = 'FinalAfterInactivityAfterEnteringPayoutAccount',
  FinalAfterInactivityAfterDACInitialization = 'FinalAfterInactivityAfterDACInitialization',
  IbanValidated = 'IbanValidated',
  AccountNumberValidated = 'AccountNumberValidated',
  BankCodeValidated = 'BankCodeValidated',
  DacWidgetTabChange = 'DacWidgetTabChange',
  RemainingAmountErrorShown = 'RemainingAmountErrorShown',
  OnNewsletterConsentLinkClicked = 'OnNewsletterConsentLinkClicked',
  BrandSource = 'BrandSource',
  SortingOption = 'SortingOption'
}

export enum InformationElement {
  Tooltip = 'tooltip',
  IntroText = 'introText'
}

export enum AutocompleteEventType {
  ReceivedResults = 'ReceivedResults',
  NoResults = 'NoResults',
  SelectResult = 'SelectResult'
}

export enum ErrorType {
  Field = 'field',
  Page = 'page'
}

export enum FinalRedirectStatus {
  Succeeded = 'succeeded',
  Failed = 'failed'
}

const scope: Record<AmplitudeEvent, Scope[]> = {
  [AmplitudeEvent.LogExecutionTime]: [Scope.Debug],
  [AmplitudeEvent.LogDependencyTimeout]: [Scope.Debug],
  [AmplitudeEvent.VisitPage]: [Scope.Business, Scope.GTM],
  [AmplitudeEvent.ViewPage]: [Scope.Business, Scope.GTM],
  [AmplitudeEvent.GenerateLead]: [Scope.Business, Scope.GTM],
  [AmplitudeEvent.GoToFinal]: [Scope.Business, Scope.GTM, Scope.Debug],
  [AmplitudeEvent.InteractWithField]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.ToggleInformation]: [Scope.Business],
  [AmplitudeEvent.ValidateTraversal]: [Scope.Debug],
  [AmplitudeEvent.ConditionalFields]: [Scope.Debug],
  [AmplitudeEvent.AddSecondDebtorClicked]: [Scope.Business],
  [AmplitudeEvent.AddSecondDebtorSeen]: [Scope.Business],
  [AmplitudeEvent.RefinancingCalculatorOpen]: [Scope.Business],
  [AmplitudeEvent.RefinancingCalculatorSavingsChange]: [Scope.Business],
  [AmplitudeEvent.UseSaveButton]: [Scope.Business],
  [AmplitudeEvent.DisplayBestOfferBox]: [Scope.Business],
  [AmplitudeEvent.UseCompanyAutocomplete]: [Scope.Business],
  [AmplitudeEvent.ChangeTabVisibility]: [Scope.Debug],
  [AmplitudeEvent.DetectAbort]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.UseHotlineFallback]: [Scope.Business],
  [AmplitudeEvent.ShowHotlineFallback]: [Scope.Debug],
  [AmplitudeEvent.VisitSealUrl]: [Scope.Debug],
  [AmplitudeEvent.AlertSamePayoutAccountUsed]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.ShowConstructionFinancingNotice]: [Scope.Business],
  [AmplitudeEvent.CtaIsVisible]: [Scope.Business],
  [AmplitudeEvent.CtaIsClicked]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.BackLinkIsClicked]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.BackButtonIsClicked]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.ErrorPageShown]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.ErrorOccurred]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDACSuccessAutoRedirect]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDACWithoutClick]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacAccordionClick]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacWrapperView]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacStateChange]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacSuccess]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacError]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacAbort]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnDacStepViewAccessConfirmation]: [
    Scope.Business,
    Scope.Debug
  ],
  [AmplitudeEvent.OnDacStepViewRegistrationSuccessful]: [
    Scope.Business,
    Scope.Debug
  ],
  [AmplitudeEvent.OnDacTranscationalIdNull]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnLoginClicked]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnTaurineStartsLoading]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnTaurineBecomesUsable]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.ParentFrameStartLoadTime]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.FinalAfterInactivityAfterEnteringPayoutAccount]: [
    Scope.Business,
    Scope.Debug
  ],
  [AmplitudeEvent.FinalAfterInactivityAfterDACInitialization]: [
    Scope.Business,
    Scope.Debug
  ],
  [AmplitudeEvent.IbanValidated]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.AccountNumberValidated]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.BankCodeValidated]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.DacWidgetTabChange]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.RemainingAmountErrorShown]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.OnNewsletterConsentLinkClicked]: [
    Scope.Business,
    Scope.Debug
  ],
  [AmplitudeEvent.BrandSource]: [Scope.Business, Scope.Debug],
  [AmplitudeEvent.SortingOption]: [Scope.Business, Scope.Debug]
}

export enum PathToTracking {
  '/common/payoutAccount/iban' = AmplitudeEvent.IbanValidated,
  '/common/payoutAccount/accountNumber' = AmplitudeEvent.AccountNumberValidated,
  '/common/payoutAccount/bankCode' = AmplitudeEvent.BankCodeValidated
}

export const successTrackableFields = [
  '/common/payoutAccount/iban',
  '/common/payoutAccount/accountNumber',
  '/common/payoutAccount/bankCode'
]

export function getScope(event: AmplitudeEvent): Scope[] {
  return scope[event]
}
